import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/http2-new-features",
  "date": "2015-10-17",
  "title": "HTTP2: NEW FEATURES",
  "author": "admin",
  "tags": ["development", "http2"],
  "featuredImage": "feature.jpg",
  "excerpt": "HTTP/2 (originally named HTTP/2.0) is the second major version of the HTTP network protocol used by the World Wide Web."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Why HTTP/2?`}</h2>
    <h3>{`1. Resource Intensive Page Loading`}</h3>
    <p>{`Loading a Web page is more resource-intensive than ever, and loading all of those assets efficiently is difficult because HTTP practically only allows one outstanding request per TCP connection.`}</p>
    <h3>{`2. Multiple TCP Connections`}</h3>
    <p>{`In the past, browsers have used multiple TCP connections to issue parallel requests. However, there are limits to this; if too many connections are used, it’s both counter-productive (TCP congestion control is effectively negated, leading to congestion events that hurt performance and the network), and it’s fundamentally unfair (because browsers are taking more than their share of network resources).`}</p>
    <h3>{`3. Duplicate Data`}</h3>
    <p>{`A large number of requests back and forth from the browser to a server, means a lot of duplicated data `}<strong parentName="p">{`on the wire`}</strong>{`.`}</p>
    <h3>{`4. Hacks`}</h3>
    <p>{`It’s considered Best Practice to do things like `}<strong parentName="p">{`spriting`}</strong>{`, `}<strong parentName="p">{`data: inlining`}</strong>{`, `}<strong parentName="p">{`domain sharding`}</strong>{` and `}<strong parentName="p">{`concatenation`}</strong>{`. These hacks are indications of underlying problems in the protocol itself, and cause a number of problems on their own when used.`}</p>
    <h2>{`Differences Between HTTP1.1`}</h2>
    <p>{`The proposed changes do not require any changes to how existing web applications work, but new applications can take advantage of new features for increased speed.`}</p>
    <p>{`HTTP/2 leaves most of HTTP 1.1's high level syntax, such as `}<strong parentName="p">{`methods`}</strong>{`, `}<strong parentName="p">{`status code`}</strong>{`s, `}<strong parentName="p">{`header field`}</strong>{`s, and `}<strong parentName="p">{`URI`}</strong>{`s, the same. The element that is modified is how the data is framed and transported between the client and the server.`}</p>
    <h3>{`1. Backward Compatible`}</h3>
    <p>{`HTTP/2 will still work with the existing Web. So HTTP/2 will not introduce new methods, change headers or status codes.`}</p>
    <h3>{`2. Reduced Requests`}</h3>
    <p>{`HTTP/1 use techniques like `}<strong parentName="p">{`inlining`}</strong>{`, `}<strong parentName="p">{`concatenation`}</strong>{` and `}<strong parentName="p">{`spriting`}</strong>{` to reduce the number of requests on a page.`}</p>
    <p>{`HTTP/2 overcomes the above issue by using `}<strong parentName="p">{`multiplexing`}</strong>{` to allow many messages to be interleaved together on a connection at the same time so that one large response (or one that takes a long time for the server to process) doesn’t block others.`}</p>
    <Message type="info" title="Why is HTTP/2 multiplexed?" content="HTTP/1.x has a problem called **head-of-line blocking**, where effectively only one request can be outstanding on a connection at a time.
    Multiplexing addresses these problems by allowing multiple request and response messages to be in flight at the same time; it’s even possible to intermingle parts of one message with another on the wire. This, in turn, allows a client to use just one connection per origin to load a page." mdxType="Message" />
    <p>{`HTTP/2 adds `}<strong parentName="p">{`header compression`}</strong>{` so that the normal request and response headers don’t dominate your bandwidth — even if what you’re requesting is very small. This is a huge win on mobile, where getting big request headers can easily blow out the load time of a page with a lot of resources by several round trips.`}</p>
    <h3>{`3. Less Load on Network & Servers`}</h3>
    <p>{`With fewer connections of HTTP/2 will ease the load on both the network and the servers. With the increasing use of mobiles, as an example say a phone opens up 4-6 TCP connections to each server to download page resources, it could end up overloading mobile network buffers causing issues like dropping packets.`}</p>
    <Message type="info" title="Why is HTTP/2 multiplexed?" content="HTTP/2 allows the use of a single connection per host and encourages sites to consolidate their content on one host where possible." mdxType="Message" />
    <h3>{`4. Server Push`}</h3>
    <p>{`HTTP/2 employs `}<strong parentName="p">{`server push`}</strong>{` which allows a server to proactively send resources: HTML, CSS and images to the client’s cache for future use.`}</p>
    <p>{`This helps eliminating round trips to the server for fetching linked style sheets and image etc.`}</p>
    <Message type="info" title="Cache" content="**Server push** can be useful for proactively updating or invalidating clientside cache." mdxType="Message" />
    <p>{`If a client decides that it already has a copy, it can say "no" by with `}<inlineCode parentName="p">{`RST_STREAM`}</inlineCode>{`.`}</p>
    <h3>{`5. RST_STREAM`}</h3>
    <p>{`In HTTP/1, if client sends a request and then finds out it doesn’t need the response, it needs to close the connection to save bandwidth; there’s no safe way to recover it. `}</p>
    <p>{`HTTP/2 introduces `}<strong parentName="p">{`RST_STREAM frame`}</strong>{` to allow a client to change its mind in scenarios like browser navigating away from the page, or user cancelling a download.`}</p>
    <h3>{`6. Encryption`}</h3>
    <p>{`Even though using TLS(Transport Layer Security) is not mandatory for HTTP/2, Many believe the only safe way to deploy the new protocol is to use encryption.`}</p>
    <p>{`Chrome and Firefox are only going to support HTTP/2 using TLS, as it is difficult to deploy the new protocol across internet, given many of the `}<strong parentName="p">{`middleboxes`}</strong>{` like proxies and firewalls assume that HTTP/1 will not change.`}</p>
    <Message type="info" title="TLS(Transport Layer Security)" content="Transport Layer Security (TLS) is a protocol that ensures privacy between communicating applications and their users on the Internet. When a server and client communicate, TLS ensures that no third party may eavesdrop or tamper with any message. TLS is the successor to the Secure Sockets Layer (SSL)." mdxType="Message" />
    <h3>{`7. No More Text`}</h3>
    <p>{`With HTTP/1, you can open up a telnet session and type in a request and then look at the response. But this will not be feasible with HTTP/2, because it's a binary protocol.`}</p>
    <p>{`Textual protocols have many issues:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Higher overhead to parse`}</strong>{` - In contrast, binary protocols are simpler, and have less overhead to parse.`}</li>
      <li parentName="ul"><strong parentName="li">{`Delimiters`}</strong>{` - Newlines, whitespace handling, extra characters etc.`}</li>
      <li parentName="ul"><strong parentName="li">{`Security`}</strong>{` - Different implmentations pay the way to malicious parties to make their way in.`}</li>
      <li parentName="ul"><strong parentName="li">{`Processing text`}</strong>{` - Anything remotely look like HTTP/1 will be processed as HTTP/1.`}</li>
    </ul>
    <Message type="info" title="Tools" content="Wireshark has a plugin for dissecting HTTP/2." mdxType="Message" />
    <p>{`It’s more accurate to view the new protocol as removing some key impediments to performance; once browsers and servers learn how and when to take advantage of that, performance should start incrementally improving.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      